var graph = require("@microsoft/microsoft-graph-client");

function getAuthenticatedClient(accessToken) {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done) => {
      done(null, accessToken.accessToken);
    },
  });

  return client;
}

export async function getUserDetails(accessToken) {
  const client = getAuthenticatedClient(accessToken);

  const user = await client.api("/me").get();
  return user;
}

export async function getUserProfilePhoto(accessToken) {
  const client = getAuthenticatedClient(accessToken);

  try {
    const photo = await client.api("/me/photos/48x48/$value").get();
    return window.URL.createObjectURL(photo);
  } catch (e) {
    if (window.REACT_APP_DEBUG) console.log("Graph: " + e);
    return null;
  }
}
